import { useQuery } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';
import { studentReportState } from 'recoil/report/student/studentReportState';
import { teacherReportState } from 'recoil/report/teacher/teacherReportState';
import { teacherStudentState } from 'recoil/report/teacher/teacherStudentState';

export const useReportQuery = () => {
  const user = useRecoilValue(loginUser);
  const selectedTerm = useRecoilValue(studentReportState);
  const reportState = useRecoilValue(studentReportState);
  const reacherReportState = useRecoilValue(teacherReportState);
  const teacherStudentStateID = useRecoilValue(teacherStudentState);
  const { data: 학기_선택박스_정보, isLoading: 학기_선택박스_로딩 } = useQuery({
    queryKey: ['studentReportSelector', user],
    queryFn: () => getDataQuery(`/terms?account_id=1`),
  });

  // 학생
  const { data: 학생_학과_정보, isLoading: 학생_학과_로딩 } = useQuery({
    queryKey: ['학생_학과_정보', user, selectedTerm],
    queryFn: () => getDataQuery(`/statics/sub-division/student/me?term_id=${selectedTerm?.value}`),
    enabled: user.role === 'STUDENT' && !!selectedTerm,
  });
  const { data: 학생_섹션_차트_일번_정보, isLoading: 학생_섹션_차트_일번_로딩 } = useQuery({
    queryKey: ['학생_섹션_차트_일번_정보', user, selectedTerm],
    queryFn: () => getDataQuery(`/statics/first/me?term_id=${selectedTerm?.value}`),
    enabled: !!selectedTerm,
  });
  const { data: 학생_섹션_이번_교양시청_정보, isLoading: 학생_섹션_이번_교양시청_로딩 } = useQuery({
    queryKey: ['학생_섹션_이번_교양시청_정보', user, selectedTerm],
    queryFn: () => getDataQuery(`/statics/second-two/me?term_id=${selectedTerm?.value}`),
    enabled: !!selectedTerm,
  });
  const { data: 학생_답변_조회, isLoading: 학생_답변_조회_로딩 } = useQuery({
    queryKey: ['학생_답변_조회', user, selectedTerm],
    queryFn: () => getDataQuery(`/statics/answer`),
    enabled: user?.role === 'STUDENT' && !!selectedTerm,
  });

  const { data: 학생_섹션_차트_조회_정보, isLoading: 학생_섹션_차트_조회_로딩 } = useQuery({
    queryKey: [
      '학생_섹션_차트_조회_정보',
      user,
      selectedTerm,
      학생_학과_정보?.data?.student_number,
      학생_학과_정보?.data?.sub_division,
    ],
    queryFn: () =>
      getDataQuery(
        `/statics/individual/score?term_id=${reportState?.value}&student_id=${학생_학과_정보?.data?.student_id}&subdivision_name=${학생_학과_정보?.data?.sub_division}`
      ),
    enabled:
      !!selectedTerm &&
      !!reportState?.value &&
      !!학생_학과_정보?.data?.student_number &&
      !!학생_학과_정보?.data?.sub_division,
  });

  // 교수
  const { data: 교수학과_정보, isLoading: 교수학과_로딩 } = useQuery({
    queryKey: ['교수학과_정보', user],
    queryFn: () => getDataQuery(`/statics/sub-division/teacher/me`),
    enabled: user?.role !== 'STUDENT',
  });
  const { data: 선생_학과_통계_일_조회_정보, isLoading: 선생_학과_통계_일_조회_로딩 } = useQuery({
    queryKey: ['선생_학과_통계_일_조회_정보', user, reportState?.value, reacherReportState?.value],
    queryFn: () =>
      getDataQuery(
        `/statics/first/sub-division?term_id=${reportState?.value}&sub_division_id=${reacherReportState?.value}`
      ),
    enabled: user?.role === 'TEACHER' && !!reportState?.value && !!reacherReportState?.value,
  });

  const { data: 선생_학과_통계_이_조회_정보, isLoading: 선생_학과_통계_이_조회_로딩 } = useQuery({
    queryKey: ['선생_학과_통계_이_조회_정보', user, user, reportState?.value, reacherReportState?.value],
    queryFn: () =>
      getDataQuery(
        `/statics/second-two/sub-division?term_id=${reportState?.value}&sub_division_id=${reacherReportState?.value}`
      ),
    enabled: user?.role === 'TEACHER' && !!reportState?.value && !!reacherReportState?.value,
  });
  const { data: 선생_두번째_차트_정보, isLoading: 선생_두번째_차트_로딩 } = useQuery({
    queryKey: ['선생_두번째_차트_정보', user, user, reportState?.value, 교수학과_정보?.data],
    queryFn: () =>
      getDataQuery(
        `/statics/professor/score?term_id=${reportState?.value}&subdivision_name=${교수학과_정보?.data?.sub_division_name}`
      ),
    enabled: user?.role !== 'STUDENT' && !!reportState?.value && !!교수학과_정보?.data?.sub_division_name,
  });

  console.log('reportState => => =>', reportState?.value);
  console.log('reacherReportState => => =>', reacherReportState?.value);

  const { data: 선생_자신의학생_조회_정보, isLoading: 선생_자신의학생_조회_로딩 } = useQuery({
    queryKey: ['선생_자신의학생_조회_정보', user, user, reportState?.value, reacherReportState?.value],
    queryFn: () => getDataQuery(`/statics/sub-division/students?sub_division_id=${reacherReportState?.value}`),
    enabled: user?.role === 'TEACHER' && !!reportState?.value && !!reacherReportState?.value,
  });

  //교수 => 학생 정보 조회
  const { data: 선생_학생_학과_정보, isLoading: 선생_학생_학과_로딩 } = useQuery({
    queryKey: ['선생_학생_학과_정보', user, selectedTerm, teacherStudentStateID],
    queryFn: () =>
      getDataQuery(
        `/statics/sub-division/student/me?term_id=${teacherStudentStateID?.term_id}&student_id=${teacherStudentStateID?.student_id}`
      ),
    enabled: user?.role === 'STUDENT' && !!teacherStudentStateID?.term_id && !!teacherStudentStateID?.student_id,
  });
  const { data: 선생_학생_섹션_차트_일번_정보, isLoading: 선생_학생_섹션_차트_일번_로딩 } = useQuery({
    queryKey: ['선생_학생_섹션_차트_일번_정보', user, selectedTerm, teacherStudentStateID],
    queryFn: () =>
      getDataQuery(
        `/statics/first/me?term_id=${teacherStudentStateID?.term_id}&student_id=${teacherStudentStateID?.student_id}`
      ),
    enabled: user?.role !== 'STUDENT' && !!teacherStudentStateID?.term_id && !!teacherStudentStateID?.student_id,
  });

  const { data: 선생_학생_답변_조회, isLoading: 선생_학생_답변_조회_로딩 } = useQuery({
    queryKey: ['선생_학생_답변_조회', user, selectedTerm, teacherStudentStateID],
    queryFn: () => getDataQuery(`/statics/answer?student_id=${teacherStudentStateID?.student_id}`),
    enabled: user?.role !== 'STUDENT' && !!teacherStudentStateID?.student_id,
  });

  const { data: 선생_학생_섹션_이번_교양시청_정보, isLoading: 선생_학생_섹션_이번_교양시청_로딩 } = useQuery({
    queryKey: ['선생_학생_섹션_이번_교양시청_정보', user, selectedTerm, teacherStudentStateID],
    queryFn: () =>
      getDataQuery(
        `/statics/second-two/me?term_id=${teacherStudentStateID?.term_id}&student_id=${teacherStudentStateID?.student_id}`
      ),
    enabled: user?.role !== 'STUDENT' && !!teacherStudentStateID?.term_id && !!teacherStudentStateID?.student_id,
  });

  // 어드민
  const { data: 관리자_전체학과선택_정보, isLoading: 관리자_전체학과선택_로딩 } = useQuery({
    queryKey: ['관리자_전체학과선택_정보', user, 학기_선택박스_정보],
    queryFn: () => getDataQuery(`/sub-divisions`),
    enabled: user?.role === 'ADMIN' && !!학기_선택박스_정보,
  });

  return {
    학기_선택박스_정보,
    학기_선택박스_로딩,
    학생_학과_정보,
    학생_학과_로딩,
    학생_섹션_차트_일번_정보,
    학생_섹션_차트_일번_로딩,
    학생_섹션_이번_교양시청_정보,
    학생_섹션_이번_교양시청_로딩,
    학생_섹션_차트_조회_정보,
    학생_섹션_차트_조회_로딩,
    학생_답변_조회,
    학생_답변_조회_로딩,
    // queryReportData,

    교수학과_정보,
    교수학과_로딩,
    선생_학과_통계_일_조회_정보,
    선생_학과_통계_일_조회_로딩,
    선생_학과_통계_이_조회_정보,
    선생_학과_통계_이_조회_로딩,
    선생_자신의학생_조회_정보,
    선생_자신의학생_조회_로딩,
    선생_두번째_차트_정보,
    선생_두번째_차트_로딩,

    // 교수 => 학생 정보 조회
    선생_학생_학과_정보,
    선생_학생_섹션_차트_일번_정보,
    선생_학생_섹션_이번_교양시청_정보,
    선생_학생_답변_조회,
    선생_학생_답변_조회_로딩,

    // 관리자
    관리자_전체학과선택_정보,
    관리자_전체학과선택_로딩,
  };
};
